import React from 'react';
import { AppBar, Toolbar, Typography, Box, IconButton, Menu, MenuItem, useMediaQuery } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import styled from 'styled-components';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

const HeaderContainer = styled(AppBar)`
  background-color: #3f51b5;
  width: 100%;
`;

const ToolbarContainer = styled(Toolbar)`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  box-sizing: border-box;
  align-items: center; /* Обеспечиваем выравнивание элементов по центру */
`;

const ThreatLevelContainer = styled(Box)`
  display: flex;
  align-items: center;
  margin-left: 10px;
`;

const ThreatLevelIndicator = styled.div`
  width: ${({ size }) => size || '20px'};
  height: ${({ size }) => size || '20px'};
  border-radius: 50%;
  background-color: ${({ level }) => (level === 'green' ? 'green' : level === 'yellow' ? 'yellow' : 'red')};
  margin-left: 5px; /* Уменьшаем отступ между текстом и кругом */
`;

const Logo = styled.img`
  width: 50px;
  height: 50px;
  margin-right: 10px;
  cursor: pointer;
`;

const Header = ({ magnitude }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleNavigate = (path) => {
    navigate(path);
    handleMenuClose();
  };

  const getThreatLevel = () => {
    if (magnitude < 4.5) return 'green';
    if (magnitude < 5.5) return 'yellow';
    return 'red';
  };

  return (
    <HeaderContainer position="static">
      <ToolbarContainer>
        <Box display="flex" alignItems="center" onClick={() => handleNavigate('/')} style={{ cursor: 'pointer' }}>
          <Logo src="logo.png" alt="Квейк Кам Логотип" />
          <Typography variant="h6" noWrap>
            Квейк Кам
          </Typography>
        </Box>
        <ThreatLevelContainer>
          <Typography variant="subtitle1">
            Уровень угрозы:
          </Typography>
          <ThreatLevelIndicator level={getThreatLevel()} size="24px" />
        </ThreatLevelContainer>
        <Box sx={{ display: { xs: 'flex', md: 'none' }, justifyContent: 'flex-end' }}>
          <IconButton
            color="inherit"
            edge="end"
            onClick={handleMenuOpen}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MenuItem onClick={() => handleNavigate('/contacts')}>Контакты</MenuItem>
            <MenuItem onClick={() => handleNavigate('/about')}>О нас</MenuItem>
          </Menu>
        </Box>
        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
          <Typography variant="button" component="a" onClick={() => handleNavigate('/contacts')} style={{ marginRight: '20px', color: 'white', textDecoration: 'none', cursor: 'pointer' }}>
            Контакты
          </Typography>
          <Typography variant="button" component="a" onClick={() => handleNavigate('/about')} style={{ color: 'white', textDecoration: 'none', cursor: 'pointer' }}>
            О нас
          </Typography>
        </Box>
      </ToolbarContainer>
    </HeaderContainer>
  );
};

export default Header;