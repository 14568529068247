import React, { useState, useEffect, useCallback, Suspense, lazy } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import { Container } from '@mui/material';
import moment from 'moment-timezone';
import PWAPrompt from './components/PWAPrompt';

// Lazy loading components
const EarthquakeList = lazy(() => import('./components/EarthquakeList'));
const EarthquakeMap = lazy(() => import('./components/EarthquakeMap'));
const Contacts = lazy(() => import('./pages/Contacts'));
const About = lazy(() => import('./pages/About'));
const AnimatedCounter = lazy(() => import('./components/AnimatedCounter'));

const requestNotificationPermission = async () => {
  if ('Notification' in window) {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      console.log('Notification permission granted.');
      registerServiceWorker();
    } else {
      console.error('Notification permission denied.');
    }
  } else {
    console.warn('This browser does not support notifications.');
  }
};

const registerServiceWorker = () => {
  if ('serviceWorker' in navigator && 'PushManager' in window) {
    navigator.serviceWorker.register('/service-worker.js')
      .then((swReg) => {
        console.log('Service Worker Registered', swReg);
      })
      .catch((error) => {
        console.error('Service Worker Registration Failed', error);
      });
  }
};

const App = () => {
  const [earthquakes, setEarthquakes] = useState([]);
  const [selectedEarthquake, setSelectedEarthquake] = useState(null);

  const parseEarthquakeData = useCallback((html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const rows = doc.querySelectorAll('table.table-sm tr');
    
    const parsedEarthquakes = [];

    rows.forEach((row, index) => {
      if (index === 0) return;

      const date = row.children[1].innerText.trim();
      const latitude = parseFloat(row.children[2].innerText.trim());
      const longitude = parseFloat(row.children[3].innerText.trim());
      const depth = parseInt(row.children[4].innerText.trim(), 10);
      const magnitude = parseFloat(row.children[5].innerText.trim());

      const kamchatkaTime = moment.tz(date, 'UTC').tz('Asia/Kamchatka').format('YYYY-MM-DD HH:mm:ss');

      parsedEarthquakes.push({ date: kamchatkaTime, latitude, longitude, depth, magnitude });
    });

    return parsedEarthquakes;
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get('https://api.quake41.ru/proxy'); // Используем Flask-прокси
        const parsedData = parseEarthquakeData(data);
        setEarthquakes(parsedData);
        setSelectedEarthquake(parsedData[0]); // Выбираем последнее землетрясение
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
    requestNotificationPermission();
  }, [parseEarthquakeData]);

  return (
    <Router>
      <Header magnitude={selectedEarthquake?.magnitude || 0} />
      <Container>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route exact path="/" element={
              <>
                <AnimatedCounter />
                <EarthquakeMap earthquake={selectedEarthquake} />
                <EarthquakeList
                  earthquakes={earthquakes}
                  onSelect={setSelectedEarthquake}
                />
              </>
            } />
            <Route path="/contacts" element={<Contacts />} />
            <Route path="/about" element={<About />} />
          </Routes>
          <PWAPrompt /> {/* Добавляем PWA Prompt */}
        </Suspense>
      </Container>
    </Router>
  );
};

export default React.memo(App);