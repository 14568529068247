import React, { useState, useEffect } from 'react';
import { Button, Snackbar } from '@mui/material';

const PWAPrompt = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Проверяем, был ли пользователь уже уведомлен
    const hasPrompted = localStorage.getItem('pwaPromptDismissed');
    if (hasPrompted) return;

    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setIsVisible(true); // Показываем уведомление
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the PWA prompt');
        } else {
          console.log('User dismissed the PWA prompt');
        }
        setDeferredPrompt(null);
        setIsVisible(false);
      });
    }
  };

  const handleClose = () => {
    setIsVisible(false);
    localStorage.setItem('pwaPromptDismissed', 'true'); // Помечаем, что пользователь закрыл уведомление
  };

  return (
    <Snackbar
      open={isVisible}
      onClose={handleClose}
      message="Установите наше приложение для лучшего опыта"
      action={
        <Button color="primary" onClick={handleInstallClick}>
          Установить
        </Button>
      }
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    />
  );
};

export default PWAPrompt;